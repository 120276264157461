import React from 'react';
import { Form } from "react-bootstrap";
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

const CustomDropDown = ({ label, list, handleChange }) => {
     return (
          <div style={{ width: '100%' }}>
               <Form.Group className="form-control" className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }}>{label}</Form.Label>
                    <Select onChange={handleChange} options={list} style={{ overflow: 'scroll' }} placeholder={label} />
               </Form.Group>
          </div>
     );
}

export default CustomDropDown;