import Spinner from "react-bootstrap/Spinner";
import React from "react";

const CustomButton = ({ onClick, text, loading }) => {
     return <button style={{ marginTop: "10px", border: "0", background: "linear-gradient(89.91deg, #B061D0 0.06%, #5535B8 102.14%)", borderRadius: "5px", width: "100%",boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)'}} type="submit" onClick={onClick} className="btn btn-primary btn-block">
          {loading ? <Spinner animation="border" role="status">
               <span className="visually-hidden">Loading...</span>
          </Spinner> : <h3 style={{ color: "white", marginTop: "10px", fontSize: "18px",letterSpacing: 0.75, }}>{text}</h3>}
     </button>;
}

export default CustomButton;