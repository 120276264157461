import React, { useEffect, useState } from "react";
import cookie from 'react-cookies';
import { Form } from "react-bootstrap";
import CustomLabel from "../components/CustomLabel";
import CustomMultiDropDown from "../components/CustomMultiDropDown";
import CustomAlert from "../components/CustomAlert";
import CustomButton from "../components/CustomButton";
import TableToExcel from "@linways/table-to-excel";
import CustomCard from "../components/CustomCard";
import CustomDivider from "../components/CustomDivider";
import CustomDropDown from "../components/CustomDropDown";
import CustomDatePicker from "../components/CustomDatePicker";
import { FaTrashAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import CustomLogoutButton from "../components/CustomLogoutButton";
const axios = require('axios').default;
const { Parser } = require('json2csv');
var FileSaver = require('file-saver');

const CreateOrderScreen = props => {
     const [alertInfo, setAlertInfo] = useState({ visibility: false, type: 'danger', message: '' });

     function Timer(time, message, type) {
          setAlertInfo({ visibility: true, type: type, message: message });
          setInterval(() => {
               setAlertInfo({ visibility: false, type: type, message: '' });
          }, time);
     }
     const [loading, setLoading] = useState(false);
     const reload = false;



     const [vehicleCategory, setVehicleCategory] = useState([
          { label: 'Vehicle Utilization', value: 'utilization' },
          // { label: 'Fuel Theft', value: 'fuel_theft' },
          { label: 'Driver Utilization', value: 'driver_utilization' },
          { label: 'Driving Ability', value: 'driving_ability' },
          // { label: 'Vehicle Fuel Consumption', value: 'fuel' },
          { label: 'Trip Data (Trucks)', value: 'trip_trucks' },
          { label: 'Trip Data (Excavators)', value: 'trip_excavators' },
          { label: 'Fuel Level Graph', value: 'fuel_level' },
          //{ label: 'Zone In Out', value: 'zone_in_out' },
          // { label: 'Current Position', value: 'current_position' },
          //{ label: 'Overspeed (60)', value: 'zone_in_out' },
          //{ label: 'Zone In Out', value: 'zone_in_out' },
     ]);

     const [startDate, setStartDate] = useState(new Date());
     const [endDate, setEndDate] = useState(new Date());
     const [vehicleList, setVehicleList] = useState([]);
     const [zoneList, setZoneList] = useState([]);
     const [status, setStatus] = useState('');
     const [vehicleName, setVehicleName] = useState([]);
     const [zoneName, setZoneName] = useState([]);
     const [resultData, setResultData] = useState([]);



     function save(filename, data) {
          const blob = new Blob([data]);
          if (window.navigator.msSaveOrOpenBlob) {
               window.navigator.msSaveBlob(blob, filename + '.html');
          }
          else {
               const elem = window.document.createElement('a');
               elem.href = window.URL.createObjectURL(blob);
               elem.download = filename + '.html';
               document.body.appendChild(elem);
               elem.click();
               document.body.removeChild(elem);
          }
     }

     const fuelStart = '<!DOCTYPE html\n' +
          '    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n' +
          '<html>\n' +
          '\n' +
          '<head>\n' +
          '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />\n' +
          '    <title>GPS Tracking Solution 5.0</title>\n' +
          '    <link rel="icon" href="https://www.speedotrack.in/favicon.ico" />\n' +
          '    <style type="text/css">\n' +
          '        @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700&subset=latin,greek,greek-ext,cyrillic,cyrillic-ext,latin-ext,vietnamese);\n' +
          '\n' +
          '        html,\n' +
          '        body {\n' +
          '            text-align: left;\n' +
          '            margin: 10px;\n' +
          '            padding: 0px;\n' +
          '            font-size: 11px;\n' +
          '            font-family: "open sans";\n' +
          '            color: #444444;\n' +
          '        }\n' +
          '\n' +
          '        .logo {\n' +
          '            border: 0px;\n' +
          '            width: 250px;\n' +
          '            height: 56px;\n' +
          '        }\n' +
          '\n' +
          '        h3 {\n' +
          '            font-size: 13px;\n' +
          '            font-weight: 600;\n' +
          '        }\n' +
          '\n' +
          '        hr {\n' +
          '            border-color: #eeeeee;\n' +
          '            border-style: solid none none;\n' +
          '            border-width: 1px 0 0;\n' +
          '            height: 1px;\n' +
          '            margin-left: 1px;\n' +
          '            margin-right: 1px;\n' +
          '        }\n' +
          '\n' +
          '        a,\n' +
          '        a:hover {\n' +
          '            text-decoration: none;\n' +
          '            color: #2b82d4;\n' +
          '        }\n' +
          '\n' +
          '        b,\n' +
          '        strong {\n' +
          '            font-weight: 600;\n' +
          '        }\n' +
          '\n' +
          '        .graph-controls {\n' +
          '            margin-bottom: 10px;\n' +
          '            display: table;\n' +
          '            width: 100%;\n' +
          '        }\n' +
          '\n' +
          '        .graph-controls div {\n' +
          '            display: inline-block;\n' +
          '            vertical-align: middle;\n' +
          '            font-size: 11px;\n' +
          '        }\n' +
          '\n' +
          '        .graph-controls-left {\n' +
          '            float: left;\n' +
          '            margin-top: 5px;\n' +
          '        }\n' +
          '\n' +
          '        .graph-controls-right {\n' +
          '            float: right;\n' +
          '        }\n' +
          '\n' +
          '        .graph-label {\n' +
          '            line-height: 24px;\n' +
          '            margin-right: 5px;\n' +
          '        }\n' +
          '\n' +
          '        .panel-button img {\n' +
          '            display: block;\n' +
          '            padding: 6px;\n' +
          '            background: #f5f5f5;\n' +
          '        }\n' +
          '\n' +
          '        .panel-button img:hover {\n' +
          '            background: #ffffff;\n' +
          '        }\n' +
          '\n' +
          '        caption,\n' +
          '        th,\n' +
          '        td {\n' +
          '            vertical-align: middle;\n' +
          '        }\n' +
          '\n' +
          '        table.report {\n' +
          '            border: 1px solid #eeeeee;\n' +
          '            border-collapse: collapse;\n' +
          '        }\n' +
          '\n' +
          '        table.report th {\n' +
          '            font-weight: 600;\n' +
          '            padding: 2px;\n' +
          '            border: 1px solid #eeeeee;\n' +
          '            background-color: #eeeeee;\n' +
          '        }\n' +
          '\n' +
          '        table.report td {\n' +
          '            padding: 2px;\n' +
          '            border: 1px solid #eeeeee;\n' +
          '        }\n' +
          '\n' +
          '        table.report td.night {\n' +
          '            background-color: #f5f5f5;\n' +
          '        }\n' +
          '\n' +
          '        table.report tr.night {\n' +
          '            background-color: #f5f5f5;\n' +
          '        }\n' +
          '\n' +
          '        table.report tr:hover {\n' +
          '            background-color: #f8f8f8;\n' +
          '        }\n' +
          '\n' +
          '        td {\n' +
          '            mso-number-format: "\@";\n' +
          '            /*force text*/\n' +
          '        }\n' +
          '    </style>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery-2.1.4.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery-migrate-1.2.1.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery.flot.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery.flot.crosshair.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery.flot.navigate.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery.flot.selection.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery.flot.time.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/jquery.flot.resize.min.js"></script>\n' +
          '    <script type="text/javascript" src="https://www.speedotrack.in/js/gs.common.js"></script>\n' +
          '\n' +
          '    <script type="text/javascript">\n' +
          '        var graphPlot = new Array();\n' +
          '\n' +
          '        function initGraph(id, graph) {\n' +
          '            if (!graph) {\n' +
          '                var data = []; // if no data, just create array for empty graph\n' +
          '                var units = "";\n' +
          '                var steps_flag = false;\n' +
          '                var points_flag = false;\n' +
          '            }\n' +
          '            else {\n' +
          '                var data = graph["data"];\n' +
          '                var units = graph["units"];\n' +
          '\n' +
          '                if (graph["result_type"] == "logic") {\n' +
          '                    var steps_flag = true;\n' +
          '                    var points_flag = false;\n' +
          '                }\n' +
          '                else {\n' +
          '                    var steps_flag = false;\n' +
          '                    var points_flag = false;\n' +
          '                }\n' +
          '            }\n' +
          '\n' +
          '            var minzoomRange = 30000;//	min zoom in is within 1 minute range (1*60*1000 = 60000)\n' +
          '            var maxzoomRange = 30 * 86400000;//	max zoom out is 5 times greater then chosen period (default is equal to 30 days 30 * 24*60*60*1000 = 86400000 )\n' +
          '\n' +
          '            var options = {\n' +
          '                xaxis: {\n' +
          '                    mode: "time",\n' +
          '                    zoomRange: [minzoomRange, maxzoomRange]\n' +
          '                },\n' +
          '                yaxis: {\n' +
          '                    //min: 0, \n' +
          '                    tickFormatter: function (v) {\n' +
          '                        var result = "";\n' +
          '                        if (graph) {\n' +
          '                            result = Math.round(v * 100) / 100 + " " + units;\n' +
          '                        }\n' +
          '                        return result;\n' +
          '                    },\n' +
          '                    zoomRange: [0, 0],\n' +
          '                    panRange: false\n' +
          '                },\n' +
          '                selection: { mode: "x" },\n' +
          '                crosshair: { mode: "x" },\n' +
          '                lines: { show: true, lineWidth: 1, fill: true, fillColor: "rgba(43,130,212,0.3)", steps: steps_flag },\n' +
          '                series: { lines: { show: true }, points: { show: points_flag, radius: 1 } },\n' +
          '                colors: ["#2b82d4"],\n' +
          '                grid: { hoverable: true, autoHighlight: true, clickable: true },\n' +
          '                zoom: {\n' +
          '                    //interactive: true,\n' +
          '                    animate: true,\n' +
          '                    trigger: "dblclick", // or "click" for single click\n' +
          '                    amount: 3         // 2 = 200% (zoom in), 0.5 = 50% (zoom out)\n' +
          '                },\n' +
          '                pan: { interactive: false, animate: true }\n' +
          '            };\n' +
          '\n' +
          '            graphPlot[id] = $.plot($("#graph_plot_" + id), [data], options);\n' +
          '\n' +
          '            $("#graph_plot_" + id).unbind("plothover");\n' +
          '            $("#graph_plot_" + id).bind("plothover", function (event, pos, item) {\n' +
          '                if (item) {\n' +
          '                    var dt_tracker = getDatetimeFromTimestamp(item.datapoint[0]);\n' +
          '\n' +
          '                    var value = item.datapoint[1];\n' +
          '                    document.getElementById("graph_label_" + id).innerHTML = value + " " + units + " - " + dt_tracker;\n' +
          '                }\n' +
          '            });\n' +
          '\n' +
          '            $("#graph_plot_" + id).unbind("plotselected");\n' +
          '            $("#graph_plot_" + id).bind("plotselected", function (event, ranges) {\n' +
          '                graphPlot[id] = $.plot($("#graph_plot_" + id),\n' +
          '                    [data],\n' +
          '                    $.extend(true, {}, options, {\n' +
          '                        xaxis: { min: ranges.xaxis.from, max: ranges.xaxis.to }\n' +
          '                    }));\n' +
          '\n' +
          '                // dont fire event on the overview to prevent eternal loop\n' +
          '                overview.setSelection(ranges, true);\n' +
          '            });\n' +
          '        }\n' +
          '\n' +
          '        function graphPanLeft(id) {\n' +
          '            graphPlot[id].pan({ left: -100 })\n' +
          '        }\n' +
          '\n' +
          '        function graphPanRight(id) {\n' +
          '            graphPlot[id].pan({ left: +100 })\n' +
          '        }\n' +
          '\n' +
          '        function graphZoomIn(id) {\n' +
          '            graphPlot[id].zoom();\n' +
          '        }\n' +
          '\n' +
          '        function graphZoomOut(id) {\n' +
          '            graphPlot[id].zoomOut();\n' +
          '        }\n' +
          '    </script>\n' +
          '</head>\n' +
          '\n' +
          '<body><img class="logo" src="https://www.speedotrack.in/img/logo.png" />\n' +
          '    <hr />\n' +
          '\n' +
          '';
     const fuelEnd = '</body></html>';




     const handleNewOrder = async (vList, vehicleName, selectedVehicleCategory) => {
          setLoading(true);
          setStatus('Starting process...');
          var zoneListTemp = [];
          let vehicleList = vList;
          if (vehicleList.length == 0) {
               let list = [];
               for (let index = 0; index < vehicleName.length; index++) {
                    const element = vehicleName[index];
                    list.push(element.value);
               }
               vehicleList = list;
          }
          if (zoneList.length == 0) {
               let list = [];
               for (let index = 0; index < zoneName.length; index++) {
                    const element = zoneName[index];
                    list.push(element.value);
               }
               console.log(list);
               zoneListTemp = list;
               setZoneList(list);
          } else {
               zoneListTemp = zoneList;
          }
          let retry = 0;
          let fuelList = [];
          let resultData = '';
          setLoading(true);
          let dataItems = '';
          let dataItems2 = '';
          let title = '';
          let reportTypeOriginal = selectedVehicleCategory;

          if (selectedVehicleCategory == 'utilization') {
               title = 'Vehicle Utilization';
               dataItems = 'route_start,route_end,route_length,move_duration,stop_duration,stop_count,top_speed,avg_speed,overspeed_count,fuel_consumption,avg_fuel_consumption,fuel_cost,engine_work,engine_idle,odometer,engine_hours,driver,trailer';
               selectedVehicleCategory = 'general';
          }
          else if (selectedVehicleCategory == 'fuel') {
               title = 'Vehicle Fuel Consumption';
               dataItems = 'route_length,engine_work,move_duration,engine_idle,fuel_consumption';
               selectedVehicleCategory = 'general';
          }
          else if (selectedVehicleCategory == 'driver_utilization') {
               title = 'Driver Utilization';
               dataItems = 'sensor,activation_time,deactivation_time,duration';
               selectedVehicleCategory = 'logic_sensors';
          }
          else if (selectedVehicleCategory == 'driving_ability') {
               title = 'Driving Ability';
               dataItems = 'top_speed,avg_speed';
               dataItems2 = 'total';
               selectedVehicleCategory = 'events';
          }
          else if (selectedVehicleCategory == 'trip_trucks') {
               title = 'Trip Data (Trucks)';
               dataItems = 'zone_in,zone_out,duration,route_length,zone_name,zone_position';
               selectedVehicleCategory = 'zone_in_out';
          }
          else if (selectedVehicleCategory == 'trip_excavators') {
               title = 'Trip Data (Excavators)';
               dataItems = 'driver,trailer';
               selectedVehicleCategory = 'general';
          }
          else if (selectedVehicleCategory == 'current_position') {
               title = 'Current Position';
               dataItems = 'time,position,speed,altitude,angle,status,odometer,engine_hours';
               selectedVehicleCategory = 'current_position';
          }
          else if (selectedVehicleCategory == 'fuel_theft') {
               title = 'Fuel Theft';
               dataItems = 'time,stolen,driver';
               selectedVehicleCategory = 'fuelthefts';
          }
          else if (selectedVehicleCategory == 'general') {
               title = 'General Information';
               dataItems = 'route_start,route_end,route_length,move_duration,stop_duration,stop_count,top_speed,avg_speed,overspeed_count,fuel_consumption,fuel_cost,engine_work,engine_idle,odometer,engine_hours,driver,trailer';
          }
          else if (selectedVehicleCategory == 'general_analysis') {
               title = 'General Analysis';
               dataItems = 'route_start,route_end,route_length,move_duration,stop_duration,stop_count,top_speed,avg_speed,overspeed_count,fuel_consumption,fuel_cost,engine_work,engine_idle,odometer,engine_hours,driver,trailer';
          }
          else if (selectedVehicleCategory == 'fuelfillings') {
               title = 'Fuel Fillings';
               dataItems = 'time,position,before,after,filled,sensor,driver,total';
          }
          else if (selectedVehicleCategory == 'fuelthefts') {
               title = 'Fuel Thefts';
               dataItems = 'time,position,before,after,stolen,sensor,driver,total';
          }
          else if (selectedVehicleCategory == 'drives_stops') {
               title = 'Drives And Stops';
               dataItems = 'status,start,end,duration,move_duration,stop_duration,route_length,top_speed,avg_speed,fuel_consumption,fuel_cost,engine_work,engine_idle';
          }
          else if (selectedVehicleCategory == 'zone_in_out') {
               title = 'Zone In Out';
               dataItems = 'zone_in,zone_out,duration,route_length,zone_name,zone_position,total';
          }
          else if (selectedVehicleCategory == 'fuel_level') {
               title = 'Fuel level';
               dataItems = '';
          }
          const dataUser = JSON.parse((cookie.load('data', { doNotParse: true }) ?? ''));
          const resultListData = [];
          var routeLength = 0;
          for (let index = 0; index < vehicleList.length; index++) {
               setStatus(`Status: Fetching report ${index + 1} of ${vehicleList.length} devices.`);
               if (reportTypeOriginal == 'utilization') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: dataItems,
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: selectedVehicleCategory,
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    }).then(function (response) {
                         return response;
                    }).then(function (result) {
                         retry = 0;
                         if (result.data.length > 0) {
                              console.log(result.data[0]["Route length (km)"]);
                              routeLength = routeLength + result.data[0]["Route length (km)"];
                              result.data[0]["Sensor Duration (ARM/Bucket)"] = result.data[0]["Wireless Angle Sensor Duration"];
                              delete result.data[0]["Wireless Angle Sensor Duration"];
                              result.data[0]["Engine Working Duration"] = result.data[0]["Engine work"];
                              delete result.data[0]["Engine work"];
                              resultListData.push(result.data[0]);
                         } else {
                              console.log('No Data!');
                         }
                    }).catch(function (params) {
                    });
               }
               else if (reportTypeOriginal == 'fuel_level') {
                    console.log('Here');
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: dataItems,
                         dtf: format(new Date(new Date(startDate).getTime() - (5 * 60 + 30) * 60000), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(new Date(endDate).getTime() - (5 * 60 + 30) * 60000), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: 'fuellevel_graph',
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    }).then(function (response) {
                         return response;
                    }).then(async function (result) {
                         var found = vehicleName.filter(function (item) { return item.value == vehicleList[index]; });
                         const report = '<h3>Fuel level graph</h3><table><tr><td><strong>Object:</strong></td><td>' + found[0].label + '</td></tr><tr><td><strong>Period:</strong></td><td>' + format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss') + ' - ' + format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss') + '</td></tr></table><br/><script type="text/javascript">$(document).ready(function () {var graph = ' + JSON.stringify(result.data[0]) + ';initGraph("' + vehicleList[index] + '_0", graph);})</script><div class="graph-controls"><div class="graph-controls-left"><b>Sensor:</b> Fuel Level</div><div class="graph-controls-right">\n' +
                              '					<div id="graph_label_' + vehicleList[index] + '_0" class="graph-label"></div>\n' +
                              '					\n' +
                              '					<a href="#" onclick="graphPanLeft("' + vehicleList[index] + '_0");">\n' +
                              '						<div class="panel-button" title="Pan left">\n' +
                              '							<img src="https://www.speedotrack.in/theme/images/arrow-left.svg" width="12px" border="0"/>\n' +
                              '						</div>\n' +
                              '					</a>\n' +
                              '					\n' +
                              '					<a href="#" onclick="graphPanRight("' + vehicleList[index] + '_0");">\n' +
                              '						<div class="panel-button" title="Pan right">\n' +
                              '							<img src="https://www.speedotrack.in/theme/images/arrow-right.svg" width="12px" border="0"/>\n' +
                              '						</div>\n' +
                              '					</a>\n' +
                              '					  \n' +
                              '					<a href="#" onclick="graphZoomIn("' + vehicleList[index] + '_0");">\n' +
                              '						<div class="panel-button" title="Zoom in">\n' +
                              '							<img src="https://www.speedotrack.in/theme/images/plus.svg" width="12px" border="0"/>\n' +
                              '						</div>\n' +
                              '					</a>\n' +
                              '					\n' +
                              '					<a href="#" onclick="graphZoomOut("' + vehicleList[index] + '_0");">\n' +
                              '						<div class="panel-button" title="Zoom out">\n' +
                              '							<img src="https://www.speedotrack.in/theme/images/minus.svg" width="12px" border="0"/>\n' +
                              '						</div>\n' +
                              '					</a>\n' +
                              '				</div>\n' +
                              '			</div>\n' +
                              '			<div id="graph_plot_' + vehicleList[index] + '_0" style="height: 150px; width:100%;"></div><br/><hr/>';
                         fuelList.push(report);
                    }).catch(function (params) {
                    });
               }
               else if (reportTypeOriginal == 'fuel') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: dataItems,
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: selectedVehicleCategory,
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    }).then(function (response) {
                         return response;
                    }).then(function (result) {
                         retry = 0;
                         if (result.data.length > 0) {
                              resultListData.push(result.data[0]);
                         } else {
                              console.log('No Data!');
                         }
                    }).catch(function (params) {
                    });
               }
               else if (reportTypeOriginal == 'driving_ability') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: dataItems,
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: 'general',
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    }).then(function (response) {
                         return response;
                    }).then(async function (result) {

                         await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                              cmd: 'report',
                              user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                              timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                              user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                              timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                              dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                              dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                              dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                              language: dataUser.language,
                              units: dataUser.units,
                              unit_distance: dataUser.unit_distance,
                              privileges: dataUser.privileges,
                              data_items: dataItems2,
                              dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                              dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                              format: 'html',
                              imei: vehicleList[index],
                              name: '',
                              sensor_names: '',
                              show_addresses: 'false',
                              show_coordinates: 'true',
                              speed_limit: '',
                              stop_duration: '1',
                              type: selectedVehicleCategory,
                              zone_ids: zoneListTemp.toString(),
                              zones_addresses: 'false',
                         }), {
                              withCredentials: true,
                              credentials: 'same-origin'
                         }).then(function (response) {
                              return response;
                         }).then(function (response) {
                              retry = 0;
                              if (result.data.length > 0) {
                                   console.log(response.data);
                                   delete result.data[0]["Wireless Angle Sensor Duration"];
                                   result.data[0]["Min Speed"] = 0;
                                   result.data[0]["Harsh Acceleration"] = response.data[0]["Harsh Acceleration"] ? response.data[0]["Harsh Acceleration"] : 0;
                                   result.data[0]["Harsh Deacceleration"] = response.data[0]["Harsh Deacceleration"] ? response.data[0]["Harsh Deacceleration"] : 0;
                                   result.data[0]["Overspeed"] = response.data[0]["Overspeed"] ? response.data[0]["Overspeed"] : 0;
                                   resultListData.push(result.data[0]);
                              } else {
                                   console.log('No Data!');
                              }
                         }).catch(function (params) {
                         });
                    }).catch(function (params) {
                    });
               }
               else if (reportTypeOriginal == 'trip_excavators') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: '',
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: selectedVehicleCategory,
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    })
                         .then(function (response) {
                              return response;
                         }).then(function (result) {
                              retry = 0;

                              console.log(result.data);
                              if (result.data.length > 0) {
                                   result.data[0]["Sensor Duration (Arm)"] = result.data[0]["Wireless Angle Sensor Duration"];
                                   delete result.data[0]["Wireless Angle Sensor Duration"];
                                   result.data[0]["Start Date"] = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
                                   result.data[0]["End Date"] = format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss');
                                   resultListData.push(result.data[0]);
                              } else {
                                   console.log('No Data!');
                              }
                         }).catch(function (params) {
                         });
               }
               else if (reportTypeOriginal == 'fuel_theft') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: 'engine_idle',
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: 'general',
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    }).then(function (response) {
                         return response;
                    }).then(async function (result) {
                         await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                              cmd: 'report',
                              user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                              timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                              user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                              timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                              dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                              dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                              dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                              language: dataUser.language,
                              units: dataUser.units,
                              unit_distance: dataUser.unit_distance,
                              privileges: dataUser.privileges,
                              data_items: dataItems,
                              dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                              dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                              format: 'html',
                              imei: vehicleList[index],
                              name: '',
                              sensor_names: '',
                              show_addresses: 'false',
                              show_coordinates: 'true',
                              speed_limit: '',
                              stop_duration: '1',
                              type: selectedVehicleCategory,
                              zone_ids: zoneListTemp.toString(),
                              zones_addresses: 'false',
                         }), {
                              withCredentials: true,
                              credentials: 'same-origin'
                         })
                              .then(function (response) {
                                   return response;
                              }).then(function (response) {
                                   retry = 0;
                                   if (response.data.length > 0) {
                                        for (let index = 0; index < response.data[0].length; index++) {
                                             let time = new moment(response.data[0][index]["Time"]).add(30, 'm').add(5, 'h').format('MM-DD-YYYY HH:mm:ss');
                                             let newJson = {
                                                  Object: result.data[0]["Object"],
                                                  Time: time,
                                                  "Fuel Theft": response.data[0][index]["Stolen"],
                                                  Driver: response.data[0][index]["Driver"]
                                             }
                                             resultListData.push(newJson);
                                        }
                                   } else {
                                        console.log('No Data!');
                                   }
                              }).catch(function (params) {
                              });
                    }).catch(function (params) {
                    });
               }
               else if (reportTypeOriginal == 'driver_utilization') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: 'engine_idle',
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: 'general',
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    }).then(function (response) {
                         return response;
                    }).then(async function (result) {
                         await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                              cmd: 'report',
                              user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                              timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                              user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                              timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                              dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                              dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                              dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                              language: dataUser.language,
                              units: dataUser.units,
                              unit_distance: dataUser.unit_distance,
                              privileges: dataUser.privileges,
                              data_items: dataItems,
                              dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                              dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                              format: 'html',
                              imei: vehicleList[index],
                              name: '',
                              sensor_names: 'Door,Ignition',
                              show_addresses: 'false',
                              show_coordinates: 'true',
                              speed_limit: '',
                              stop_duration: '1',
                              type: selectedVehicleCategory,
                              zone_ids: zoneListTemp.toString(),
                              zones_addresses: 'false',
                         }), {
                              withCredentials: true,
                              credentials: 'same-origin'
                         })
                              .then(function (response) {
                                   return response;
                              }).then(function (response) {
                                   retry = 0;
                                   console.log(response.data);
                                   if (response.data.length > 0) {
                                        response.data[0] = sort_by_key(response.data[0], 'Activation time');
                                        for (let index = 0; index < response.data[0].length - 1; index++) {
                                             if (response.data[0][index]["Sensor"] == 'Door') {
                                                  if (response.data[0][index + 1]["Sensor"] == 'Ignition') {
                                                       let newJson = {
                                                            Object: result.data[0]["Object"],
                                                            "Door Open Time": response.data[0][index]["Activation time"],
                                                            "Engine On Time": response.data[0][index + 1]["Activation time"],
                                                            "Engine Off Time": response.data[0][index + 1]["Deactivation time"],
                                                            "Door Close Time": response.data[0][index]["Deactivation time"],
                                                       }
                                                       resultListData.push(newJson);
                                                  }
                                             }
                                        }
                                   }
                                   else {
                                        console.log('No Data!');
                                   }
                              }).catch(function (params) {
                              });
                    }).catch(function (params) {
                    });
               }
               else if (reportTypeOriginal == 'trip_trucks') {
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: dataItems,
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: 'Door,Ignition',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '1',
                         type: selectedVehicleCategory,
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    })
                         .then(function (response) {
                              return response;
                         }).then(function (response) {
                              retry = 0;
                              if (response.data.length > 0) {
                                   // {Object: '10H1-4350', Zone in: '2022-06-13 16:53:27', Zone out: '2022-06-13 16:53:37', Zone name: 'B - Ground Hopper', Zone position: '22.221918,84.751213'}
                                   console.log(response.data);
                                   for (let index = 0; index < response.data[0].length - 1; index++) {
                                        var startTime = moment(response.data[0][index]["Zone out"], 'yyyy-MM-dd HH:mm:ss');
                                        var endTime = moment(response.data[0][index + 1]["Zone in"], 'yyyy-MM-dd HH:mm:ss');
                                        var duration = moment.duration(endTime.diff(startTime));
                                        var hours = parseInt(duration.asHours());
                                        var minutes = parseInt(duration.asMinutes()) % 60;
                                        let newJson = {
                                             Object: response.data[0][index]["Object"],
                                             "Zone A": response.data[0][index]["Zone name"],
                                             "Zone B": response.data[0][index + 1]["Zone name"],
                                             "Km Summary": response.data[0][index + 1]["Route length"].replace(" km", ""),
                                             "Duration": filterTime(response.data[0][index + 1]["Duration"]),
                                             "Zone A Out Time": response.data[0][index]["Zone out"],
                                             "Zone B In Time": response.data[0][index + 1]["Zone in"]
                                        }
                                        resultListData.push(newJson);
                                   }
                              }
                              else {
                                   console.log('No Data!');
                              }
                         }).catch(function (params) {
                         });
               }
               else if (reportTypeOriginal == 'current_position') {
                    console.log(reportTypeOriginal);
                    console.log(dataItems);
                    await axios.post('https://www.speedotrack.in/func/fn_reports_smfal.gen.php', new URLSearchParams({
                         cmd: 'report',
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         user_id: (cookie.load('UserID', { doNotParse: true }) ?? ''),
                         timezone: (cookie.load('timezone', { doNotParse: true }) ?? ''),
                         dst: (cookie.load('dst', { doNotParse: true }) ?? ''),
                         dst_end: (cookie.load('dst_end', { doNotParse: true }) ?? ''),
                         dst_start: (cookie.load('dst_start', { doNotParse: true }) ?? ''),
                         language: dataUser.language,
                         units: dataUser.units,
                         unit_distance: dataUser.unit_distance,
                         privileges: dataUser.privileges,
                         data_items: dataItems.toString(),
                         dtf: format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
                         dtt: format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
                         format: 'html',
                         imei: vehicleList[index],
                         name: '',
                         sensor_names: '',
                         show_addresses: 'false',
                         show_coordinates: 'true',
                         speed_limit: '',
                         stop_duration: '30',
                         type: 'current_position',
                         zone_ids: zoneListTemp.toString(),
                         zones_addresses: 'false',
                    }), {
                         withCredentials: true,
                         credentials: 'same-origin'
                    })
                         .then(function (response) {
                              return response;
                         }).then(function (response) {
                              retry = 0;
                              if (response.data.length > 0) {

                                   // {Object: '10H1-4350', Zone in: '2022-06-13 16:53:27', Zone out: '2022-06-13 16:53:37', Zone name: 'B - Ground Hopper', Zone position: '22.221918,84.751213'}
                                   console.log(response.data);
                                   for (let index = 0; index < response.data[0].length; index++) {

                                        //      var startTime = moment(response.data[0][index]["Zone out"], 'yyyy-MM-dd HH:mm:ss');
                                        //      var endTime = moment(response.data[0][index + 1]["Zone in"], 'yyyy-MM-dd HH:mm:ss');
                                        //      var duration = moment.duration(endTime.diff(startTime));
                                        //      var hours = parseInt(duration.asHours());
                                        //      var minutes = parseInt(duration.asMinutes()) % 60;
                                        //      let newJson = {
                                        //           Object: response.data[0][index]["Object"],
                                        //           "Zone A": response.data[0][index]["Zone name"],
                                        //           "Zone B": response.data[0][index + 1]["Zone name"],
                                        //           "Km Summary": getDistanceFromLatLonInKm(response.data[0][index]["Zone position"].split(',')[0], response.data[0][index]["Zone position"].split(',')[1], response.data[0][index + 1]["Zone position"].split(',')[0], response.data[0][index + 1]["Zone position"].split(',')[1]).toFixed(2),
                                        //           "Duration": hours + ':' + minutes,
                                        //           "Zone A Out Time": response.data[0][index]["Zone out"],
                                        //           "Zone B In Time": response.data[0][index + 1]["Zone in"]
                                        //      }  
                                        resultListData.push(response.data[0][index])
                                   }
                                   resultListData.push({
                                        'Object': '', 'Zone in': '', 'Zone out': '', 'Duration': '', 'Zone name': '', 'Zone position': ''
                                   });
                                   resultListData.push({
                                        'Object': '', 'Zone in': '', 'Zone out': '', 'Duration': '', 'Zone name': '', 'Zone position': ''
                                   });
                                   resultListData.push({
                                        'Object': '', 'Zone in': '', 'Zone out': '', 'Duration': '', 'Zone name': '', 'Zone position': ''
                                   });
                              }
                              else {
                                   console.log('No Data!');
                              }
                         }).catch(function (params) {
                         });
               }
          }
          const options = {
               fieldSeparator: ',',
               quoteStrings: '"',
               decimalSeparator: '.',
               showLabels: true,
               showTitle: true,
               title: title,
               useTextFile: false,
               useBom: true,
               useKeysAsHeaders: true,
               // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          };
          console.log(routeLength);
          if (reportTypeOriginal == 'utilization') {
               const finalList = [];
               for (let index = 0; index < resultListData.length; index++) {
                    const element = resultListData[index];
                    finalList.push({
                         'Object': element['Object'],
                         'Route Start': element['Route start'],
                         'Route End': element['Route end'],
                         'Route length (km)': element['Route length (km)'],
                         'Engine Move': `=("${filterTime(element['Move duration'])}")`,
                         'Stop duration': `=("${filterTime(element['Stop duration'])}")`,
                         'Engine idle': `=("${filterTime(element['Engine idle'])}")`,
                         'Wireless Angle Sensor Duration': `=("${filterTimeW(element['Sensor Duration (ARM/Bucket)'])}")`,
                         'Engine Working Duration': `=("${addTime(filterTime(element['Move duration']), filterTime(element['Engine idle']))}")`,
                    });
               }
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(finalList);
          }
          else if (reportTypeOriginal == 'fuel_level') {
               var result = fuelStart;
               for (let index = 0; index < fuelList.length; index++) {
                    const element = fuelList[index];
                    result = result + element;
               }
               result = result + fuelEnd;
               var blob = new Blob([result], { type: "text/plain;charset=utf-8" });
               FileSaver.saveAs(blob, "Fuel_Level_Graph.html");
          }
          else if (reportTypeOriginal == 'fuel') {
               const finalList = [];
               for (let index = 0; index < resultListData.length; index++) {
                    const element = resultListData[index];
                    finalList.push({
                         'Object': element['Object'],
                         'Route length (km)': element['Route length (km)'],
                         'Fuel consumption (liters)': element['Fuel consumption (liters)'],
                         'Engine Working Duration': `=("${addTime(filterTime(element['Move duration']), filterTime(element['Engine idle']))}")`,
                    });
               }
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(finalList);
          }
          else if (reportTypeOriginal == 'driving_ability') {
               console.log(resultListData);
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(resultListData);
          }
          else if (reportTypeOriginal == 'driver_utilization') {
               console.log(resultListData);
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(resultListData);
          }
          else if (reportTypeOriginal == 'trip_trucks') {
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(resultListData);
               console.log(resultListData);
          }
          else if (reportTypeOriginal == 'trip_excavators') {
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(resultListData);
               console.log(resultListData);
          }
          else if (reportTypeOriginal == 'fuel_theft') {
               console.log(resultListData);
               const csvExporter = new ExportToCsv(options);
               csvExporter.generateCsv(resultListData);
          }
          setStatus(`Status: Done`);
          setLoading(false);
     }



     const addTime = (move, idle) => {
          const hr1 = move.split(':')[0];
          const hr2 = idle.split(':')[0];
          const min1 = move.split(':')[1];
          const min2 = idle.split(':')[1];
          let finalHr = Number(hr1) + Number(hr2);
          let finalMin = Number(min1) + Number(min2);
          if (finalMin >= 60) {
               finalHr = finalHr + 1;
               finalMin = finalMin - 60;
          }
          if (finalHr >= 10) {
               finalHr = finalHr;
          }
          else {
               finalHr = `0${finalHr}`;
          }

          if (finalMin >= 10) {
               finalMin = finalMin;
          }
          else {
               finalMin = `0${finalMin}`;
          }
          return `${finalHr}:${finalMin}`;
     }

     const filterTime = (time) => {
          let finalTime = '';
          let days = 0;
          if (time.toString().includes(' d ')) {
               let hr = time.split(' d ')[0];
               if (hr.length == 1) {
                    finalTime = '0' + hr;
               }
               else {
                    finalTime = hr;
               }
               days = 24 * Number(finalTime);
          }
          if (time.toString().includes(' h ')) {
               let hr = '';
               if (time.toString().includes(' d ')) {
                    hr = time.split(' d ')[1];
               }
               else {
                    hr = time;
               }
               hr = hr.split(' h ')[0];
               if (hr.length == 1) {
                    finalTime = '0' + hr;
               }
               else {
                    finalTime = hr;
               }
               finalTime = Number(finalTime.toString()) + days;
          }
          else {
               finalTime = Number('00') + days;
          }
          if (time.toString().includes(' min ')) {
               let min = '';
               if (time.toString().includes(' d ')) {
                    min = time.split(' d ')[1];
               }
               else {
                    min = time;
               }
               if (min.toString().includes(' h ')) {

                    min = min.split(' h ')[1];
               }
               else {
                    min = min;
               }
               min = min.split(' min ')[0];
               if (min.length == 1) {
                    finalTime = finalTime + ':0' + min;
               }
               else {
                    finalTime = finalTime + ':' + min;
               }
          }
          else {
               finalTime = finalTime + ':00'
          }
          return finalTime;
     }

     const filterTimeW = (time) => {
          let finalTime = '';
          let hr = time.split(':')[0];
          if (hr.length == 1) {
               finalTime = '0' + hr;
          }
          else {
               finalTime = hr;
          }
          let min = time.split(':')[1];
          if (min.length == 1) {
               finalTime = finalTime + ':0' + min;
          }
          else {
               finalTime = finalTime + ':' + min;
          }
          return finalTime;
     }


     function sort_by_key(array, key) {
          return array.sort(function (a, b) {
               var x = a[key]; var y = b[key];
               return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          });
     }


     const deg2rad = (deg) => {
          return deg * (Math.PI / 180)
     }

     const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
          var R = 6371; // Radius of the earth in km
          var dLat = deg2rad(lat2 - lat1); // deg2rad below
          var dLon = deg2rad(lon2 - lon1);
          var a =
               Math.sin(dLat / 2) * Math.sin(dLat / 2) +
               Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
               Math.sin(dLon / 2) * Math.sin(dLon / 2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          var d = R * c; // Distance in km
          return d;
     }


     function addTimes(startTime, endTime) {
          var times = [0, 0, 0]
          var max = times.length

          var a = (startTime || '').split(':')
          var b = (endTime || '').split(':')

          // normalize time values
          for (var i = 0; i < max; i++) {
               a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
               b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
          }

          // store time values
          for (var i = 0; i < max; i++) {
               times[i] = a[i] + b[i]
          }

          var hours = times[0]
          var minutes = times[1]
          var seconds = times[2]

          if (seconds >= 60) {
               var m = (seconds / 60) << 0
               minutes += m
               seconds -= 60 * m
          }

          if (minutes >= 60) {
               var h = (minutes / 60) << 0
               hours += h
               minutes -= 60 * h
          }
          let hr = ('0' + hours).slice(-4);
          return hr + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
     }

     function fetchAllDevicesModel() {
          axios.post('https://www.speedotrack.in/func/fn_settings_custom.objects.php', new URLSearchParams({
               cmd: 'load_object_data', user_id: (cookie.load('UserID', { doNotParse: true }) ?? '')
          }), {
               withCredentials: true,
               credentials: 'same-origin'
          }).then(result => {
               const deviceList = [];
               for (let index = 0; index < Object.keys(result.data).length; index++) {
                    deviceList.push({ label: result.data[Object.keys(result.data)[index]][4], value: Object.keys(result.data)[index] });
               }
               setVehicleName(deviceList);
               // setUserList(userList);
          }).catch(params => {
               setVehicleList([]);
               Timer(7000, ' ' + params, false);
          })
     }

     function fetchAllZone() {
          axios.post('https://www.speedotrack.in/func/fn_places_custom.php', new URLSearchParams({
               cmd: 'load_zone_data', user_id: (cookie.load('UserID', { doNotParse: true }) ?? '')
          }), {
               withCredentials: true,
               credentials: 'same-origin'
          }).then(result => {

               const deviceList = [];
               for (let index = 0; index < Object.keys(result.data).length; index++) {
                    const element = result.data[Object.keys(result.data)[index]].data.name;
                    deviceList.push({ label: element, value: Object.keys(result.data)[index] });
               }
               setZoneName(deviceList);
          }).catch(params => {
               setZoneName([]);
               Timer(7000, ' ' + params, false);
          })
     }

     useEffect(() => {
          fetchAllDevicesModel();
          fetchAllZone();
     }, [reload]);

     const [selectedVehicleCategory, setSelectedVehicleCategory] = useState(null);
     return <div style={{ width: '100%', height: '100%', backgroundColor: '#F4F5F7', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <CustomCard style={{ width: 10 }}>
               <Form style={{ fontFamily: 'monospace', color: 'black', textAlign: 'start', marginBottom: '10px' }}>

                    <CustomLabel text={'Generate Report'} />
                    <CustomDivider />
                    <CustomDropDown label={'Select Report'} handleChange={(selectedOption) => {

                         setSelectedVehicleCategory(selectedOption.value);
                    }} list={vehicleCategory} />
                    <CustomMultiDropDown label={'Select Devices'} handleChange={(selectedOption) => {
                         let list = [];
                         for (let index = 0; index < selectedOption.length; index++) {
                              const element = selectedOption[index];
                              list.push(element.value);
                         }
                         setVehicleList(list);
                    }} list={vehicleName} />
                    {selectedVehicleCategory == 'trip_trucks' || selectedVehicleCategory == 'zone_in_out' ?
                         <CustomMultiDropDown label={'Select Zones'} handleChange={(selectedOption) => {
                              let list = [];
                              for (let index = 0; index < selectedOption.length; index++) {
                                   const element = selectedOption[index];
                                   list.push(element.value);
                              }
                              setZoneList(list);
                         }} list={zoneName} /> : null}
                    <CustomDivider />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                         <CustomDatePicker minDate={new Date().setMonth(new Date().getMonth() - 6)} placeholder={'Enter Start Time'} onTextChange={setStartDate} value={startDate} />
                         <CustomDatePicker minDate={new Date(startDate)} placeholder={'Enter End Time'} onTextChange={setEndDate} value={endDate} />
                    </div>
                    <CustomButton onClick={(e) => {
                         if (e) { // add?
                              e.preventDefault();
                         }
                         handleNewOrder(vehicleList, vehicleName, selectedVehicleCategory);
                    }} text={'Generate'} loading={loading} />
                    <label style={{ fontSize: '14px', letterSpacing: 0.9, color: 'black', textAlign: 'start', marginBottom: '10px', marginTop: '10px' }}>
                         {status}
                    </label>
                    <CustomLogoutButton onClick={(e) => {
                         if (e) { // add?
                              e.preventDefault();
                         }
                         cookie.remove('Status');
                         cookie.remove('UserID');
                         cookie.remove('Email')
                         cookie.remove('Admin');
                         cookie.remove('AdminLevel');
                         window.location.reload();
                    }} text={'Logout'} loading={false} />
               </Form>
          </CustomCard>
          <CustomAlert info={alertInfo} />

     </div >
}

export default CreateOrderScreen;