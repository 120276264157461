import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import React from "react";
import { Card, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import cookie from 'react-cookies'
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router'
const axios = require('axios').default;


function SiteAdminLogin(props) {
     const [visibility, setVisibility] = React.useState(false);
     const [loading, setLoading] = React.useState(false);
     const [alertMessage, setAlertMessage] = React.useState('Error: Email and password cannot be empty.');
     const [email, setEmail] = React.useState('');
     const [password, setPassword] = React.useState('');


     if (cookie.load('Status', { doNotParse: true }) ?? false) {
          props.history.replace('home');
     }

     function saveCookies(value) {
          cookie.save('Status', JSON.parse(value).status, {
               path: '/',
               secure: false
          })
          cookie.save('UserID', JSON.parse(value).userID, {
               path: '/',
               secure: false
          })
          cookie.save('timezone', JSON.parse(value).timezone, {
               path: '/',
               secure: false
          })
          cookie.save('dst', JSON.parse(value).dst, {
               path: '/',
               secure: false
          })
          cookie.save('dst_end', JSON.parse(value).dst_end, {
               path: '/',
               secure: false
          })
          cookie.save('dst_start', JSON.parse(value).dst_start, {
               path: '/',
               secure: false
          })
          cookie.save('data', JSON.parse(value).data, {
               path: '/',
               secure: false
          })
          props.history.replace('home');
     }

     function Timer(time, message) {
          setAlertMessage(message);
          setVisibility(true);
          setInterval(() => {
               setVisibility(false);
          }, time);
     }

     function handleLogin(e) {
          if (e) { // add?
               e.preventDefault();
               //      e.persist();
          }
          if (!email || !password) {
               Timer(5000, 'Email and Password cannot be empty.');
          }
          else {
               setLoading(true);
               axios.post("https://www.speedotrack.in/func/fn_connect_custom.php", new URLSearchParams({
                    cmd: 'login', mobile: 'false',
                    password: password,
                    remember_me: 'true',
                    username: email
               }), {
                    withCredentials: true,
                    credentials: 'same-origin'
               }).then(result => {
                    console.log(result.data);
                    if (result === 'ERROR_MANY_FAILED_LOGIN_ATTEMPTS') {
                         setLoading(false);
                         Timer(7000, 'Too many wrong attemps.');
                         return;
                    }
                    else if (result === 'ERROR_ACCOUNT_LOCKED') {
                         setLoading(false);
                         Timer(7000, 'Account locked.');
                         return;
                    }
                    else if (result === 'ERROR_USERNAME_PASSWORD_INCORRECT') {
                         setLoading(false);
                         Timer(7000, 'Kindly check your username and password.');
                         return;
                    }
                    else {
                         setLoading(false);
                         saveCookies(JSON.stringify({ status: true, userID: result.data.user_id, timezone: result.data.timezone,dst: result.data.dst, dst_end: result.data.dst_end, dst_start: result.data.dst_start,data:result.data }));
                    }
               }).catch(function (params) {
                    console.log(params);
                    setLoading(false);
                    Timer(7000, ' ' + params);
               });
          }
     }


     return <div style={{ backgroundColor: 'white', height: '100%', width: '100%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Alert style={{ margin: "10px", position: "absolute", right: "0px", top: "0px" }} show={visibility} variant="danger">
               <Alert.Heading style={{ fontSize: "16px" }}>{alertMessage}</Alert.Heading>
          </Alert>
          <Card style={{ width: '40%', heigh: '50%', backgroundColor: '#ecf0f1', position: 'absolute' }}>
               <Card.Body>
                    <div style={{
                         display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                         <img style={{
                              marginTop: "5px", marginBottom: "5px", height: "auto", width: "40%"
                         }} src={process.env.PUBLIC_URL + '/images/colored_logo.png'} alt="dashboard_image" /></div>
                    <Form>
                         <h5 style={{ textAlign: "center" }}>Speedotrack Report Module</h5>
                         <Form.Group onChange={function (element) {
                              setEmail(element.target.value);
                         }} type="email" value={email} className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control type="email" placeholder="Enter email" />
                         </Form.Group>
                         <Form.Group onChange={function (element) {
                              setPassword(element.target.value);
                         }} type="password" value={password} className="form-control" placeholder="Enter password" className="mb-3" controlId="formBasicPassword">
                              <Form.Label  >Password</Form.Label>
                              <Form.Control type="password" placeholder="Password" />
                         </Form.Group>
                         <button style={{ marginTop: "10px", border: "0", background: "linear-gradient(89.91deg, #B061D0 0.06%, #5535B8 102.14%)", borderRadius: "3px", width: "100%" }} type="submit" onClick={handleLogin} className="btn btn-primary btn-block">
                              {loading ? <Spinner animation="border" role="status">
                                   <span className="visually-hidden">Loading...</span>
                              </Spinner> : <h3 style={{ color: "white", marginTop: "10px", fontSize: "18px", fontFamily: "Poppins" }}>Sign In</h3>}
                         </button>
                    </Form>
               </Card.Body>
          </Card>
     </div>;
}

export default SiteAdminLogin;
