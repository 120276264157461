import Spinner from "react-bootstrap/Spinner";
import React from "react";

const CustomLogoutButton = ({ onClick, text, loading }) => {
     return <button style={{ marginTop: "10px", border: "0", background: "white", borderRadius: "5px", width: "20%",boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)'}} type="submit" onClick={onClick} className="btn btn-primary btn-block">
          {loading ? <Spinner animation="border" role="status">
               <span className="visually-hidden">Loading...</span>
          </Spinner> : <h3 style={{ color: "black", marginTop: "10px", fontSize: "18px",letterSpacing: 0.75, }}>{text}</h3>}
     </button>;
}

export default CustomLogoutButton;