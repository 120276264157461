import React, { useState } from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import SiteAdminLogin from './site-admin/SiteAdminLogin';
import SiteAdminHome from './site-admin/SiteAdminHome';
import cookie from 'react-cookies'

function App() {
  const [isLoggedIn, setLogin] = useState(cookie.load('Status', { doNotParse: true }) ?? false);
  function handleLogin(value) {
    cookie.save('Status', JSON.parse(value).status, {
      path: '/',
      secure: false
    })
    cookie.save('UserID', JSON.parse(value).userID, {
      path: '/',
      secure: false
    })
    cookie.save('Email', JSON.parse(value).email, {
      path: '/',
      secure: false
    })
    setLogin(JSON.parse(value).status);
    window.location.reload();
  }
  function handleLogout(value) {
    cookie.remove('Status', { path: '/' })
    cookie.remove('UserID', { path: '/' })
    cookie.remove('Status', { path: '/' })
    setLogin(JSON.parse(value).status);
    window.location.reload();
  }

  // return <h3 style={{ color: "black", margin: "10px", fontSize: "18px", fontFamily: "Poppins" }}>System under maintenance</h3>
  return (<BrowserRouter>
    <div>
      <Switch>
        <Route path="/" component={SiteAdminLogin} exact />
        <Route path="/home" component={SiteAdminHome} />
        <Route component={SiteAdminLogin} />
      </Switch>
    </div>
  </BrowserRouter>);
}

export default App;
