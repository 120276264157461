import React from "react";
import { FormControl, Form, FormLabel } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const CustomDatePicker = ({ placeholder, value, onTextChange,minDate}) => {
     console.log(value);
     return <Form.Group>
          <Form.Label style={{ fontSize: '14px', letterSpacing: 0.75, color: 'grey', textAlign: 'start', marginBottom: '5px' }}>{placeholder}</Form.Label>
          <DatePicker dateFormat="dd-MM-yyyy HH:mm" selected={value}
          minDate={minDate} onChange={onTextChange} showTimeSelect />
     </Form.Group>;
}

export default CustomDatePicker