import React from "react";
import CreateOrderScreen from "./screens/CreateOrderScreen";
import cookie from 'react-cookies'

const SiteAdminHome = props => {

     if (!cookie.load('Status', { doNotParse: true }) ?? false) {
          props.history.replace('/');
     }


     return <div style={{ backgroundColor: '#F0E5CF', height: '100%', width: '100%', }}>

          <div style={{ backgroundColor: '#FFFFFF', height: '7.5%', width: '100%', position: 'absolute', left: 0, alignItems: 'center', display: 'flex', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)', borderBottomRightRadius: '10px' }}>
               <img style={{ height: "60%", width: "auto", marginLeft: 10 }} src={process.env.PUBLIC_URL + '/images/colored_logo.png'} alt="dashboard_image" />
          </div>

          <div style={{ backgroundColor: '#F4F5F7', height: '92.5%', width: '100%', right: 0, position: 'absolute', top: '7.5%', display: 'flex' }}>
               <CreateOrderScreen />
          </div>

     </div>;
}

export default SiteAdminHome;


// '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' +
//                '					<html>' +
//                '					<head>' +
//                '					<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />' +
//                '					<title>GPS 3.18</title>' +
//                '					<link rel="icon" href="https://www.speedotrack.in/favicon.ico" /><style type=\'text/css\'>@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700&subset=latin,greek,greek-ext,cyrillic,cyrillic-ext,latin-ext,vietnamese);' +
//                '				' +
//                '				html, body {' +
//                '					text-align: left; ' +
//                '					margin: 10px;' +
//                '					padding: 0px;' +
//                '					font-size: 11px;' +
//                '					font-family: \'open sans\';' +
//                '					color: #444444;' +
//                '				}.logo { border:0px; width:250px; height:56px; }' +
//                '		' +
//                '				h3 { ' +
//                '					font-size: 13px;' +
//                '					font-weight: 600;' +
//                '				}' +
//                '				' +
//                '				hr {' +
//                '					border-color: #eeeeee;' +
//                '					border-style: solid none none;' +
//                '					border-width: 1px 0 0;' +
//                '					height: 1px;' +
//                '					margin-left: 1px;' +
//                '					margin-right: 1px;' +
//                '				}' +
//                '				' +
//                '				a,' +
//                '				a:hover { text-decoration: none; color: #2b82d4; }' +
//                '				b, strong{ font-weight: 600; }' +
//                '				' +
//                '				.graph-controls' +
//                '				{' +
//                '					margin-bottom: 10px;' +
//                '					display: table;' +
//                '					width: 100%;' +
//                '				}' +
//                '				.graph-controls div' +
//                '				{' +
//                '					display: inline-block;' +
//                '					vertical-align: middle;' +
//                '					font-size: 11px;' +
//                '				}' +
//                '				.graph-controls-left' +
//                '				{' +
//                '					float: left;' +
//                '					margin-top: 5px;' +
//                '				}' +
//                '				.graph-controls-right' +
//                '				{' +
//                '					float: right;' +
//                '				}' +
//                '				.graph-label' +
//                '				{' +
//                '					line-height: 24px;' +
//                '					margin-right: 5px;' +
//                '				}' +
//                '				.panel-button img {' +
//                '					display: block;' +
//                '					padding: 6px;' +
//                '					background: #f5f5f5;' +
//                '				}				' +
//                '				.panel-button img:hover {' +
//                '					background: #ffffff;' +
//                '				}' +
//                '				' +
//                '				caption,' +
//                '				th,' +
//                '				td { vertical-align: middle; }' +
//                '				' +
//                '				table.report {' +
//                '					border: 1px solid #eeeeee;' +
//                '					border-collapse: collapse;' +
//                '				}' +
//                '				' +
//                '				table.report th {' +
//                '					font-weight: 600;' +
//                '					padding: 2px;' +
//                '					border: 1px solid #eeeeee;' +
//                '					background-color: #eeeeee;' +
//                '				}' +
//                '				' +
//                '				table.report td {' +
//                '					padding: 2px;' +
//                '					border: 1px solid #eeeeee;' +
//                '				}' +
//                '				' +
//                '				table.report td.night {' +
//                '					background-color: #f5f5f5;' +
//                '				}' +
//                '				' +
//                '				table.report tr.night {' +
//                '					background-color: #f5f5f5;' +
//                '				}' +
//                '				' +
//                '				table.report tr:hover { background-color: #f8f8f8; }' +
//                '				' +
//                '				td { mso-number-format:\'\@\';/*force text*/ }' +
//                '				' +
//                '			</style></head><body><img class="logo" src="https://www.speedotrack.in/img/logo.png" /><hr/><h3>General Analysis</h3><br/><table class="report" width="100%"><tr align="center"><th>Object</th><th>Route Start</th><th>Route End</th><th>Route Length</th><th>Move Duration</th><th>Stop Duration</th><th>Stop Count</th><th>Overspeed Count</th><th>Fuel Concumption</th><th>Fuel consumption (Km/l)</th><th>Engine Work</th><th>Engine Idle</th><th>Engine Hours</th><th>Wireless Angle Sensor Duration</th><th>Pay load bucket</th></tr>';